import React, {useEffect} from "react";
import classes from './pageInner.module.css'
// @ts-ignore
import Slider from 'react-slick';
import {useCountries} from "../../../hooks/countries";
import {usePrograms} from "../../../hooks/programs";

const imageBottom = require('./../../../assets/img/home-image-bottom.png')
const imageTop = require('./../../../assets/img/home-image-top.png')
const backGroundVideo = require('./../../../assets/video/homeBackgroundVideo.mp4')

export function PageInner() {

    const {loading, error, programs} = usePrograms()


    return (
        <div className={classes.pageWrapper}>
            <video autoPlay muted loop className={classes.myVideo}>
                <source src={backGroundVideo} type="video/mp4"/>
            </video>
            <div className='width'>
                <div>

                <div className={classes.page}>
                    <div className={classes.squarePurple + ' parallax'}> </div>
                    <img src={imageBottom} className={classes.imageBottom + ' parallax'} alt=""/>
                    <img src={imageTop} className={classes.imageTop + ' parallax'} alt=""/>
                    <div className={classes.contentWrapper}>
                        <p className={classes.title}>
                            Global Accelerator
                        </p>
                        <div className={classes.itemList}>
                            <div className={classes.listItem}>
                                * {programs.length} countries
                            </div>
                            <div className={classes.listItem}>
                                * Tailored syllabus
                            </div>
                            <div className={classes.listItem}>
                                * 2 days bootcamp
                            </div>
                            <div className={classes.listItem}>
                                * 30 000 USD Prize pool
                            </div>
                            <div className={classes.listItem}>
                                * 6 weeks of acceleration
                            </div>
                            <div className={classes.listItem}>
                                * International Mentors
                            </div>
                        </div>
                    </div>

                </div>
                    <p className={classes.aboutText}>
                        ICESCO powered Global Accelerator to support
                        <br/>
                        Member States clean tech economy by igniting 150+ new scale- ups by the end of 2025
                    </p>
                </div>
            </div>
        </div>
    )

}
